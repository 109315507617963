import React from "react";
import { NavLink } from "react-router-dom";
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa';

const Footer = (props) => {
  return (
    <>
      <div className="footer-page">
        <div className="footer-wrap">
          <div className="footer-title">
            <span className="footer-lable">Products</span>
            <ul>
            <li>
              <NavLink to="./osho_models">OCXO models</NavLink>
            </li>  
              <li>
                <NavLink to="./rtcc22">High Precision Low Power Clocks.</NavLink>
              </li>  
              <li>
                  <NavLink to="./usfr22">Disciplined Low Power Frequency Reference.</NavLink>
              </li>
              <li>
                <a href="https://blog.xtalball.com">News, Articles</a>
              </li>
            </ul>
          </div>

          <div className="footer-title">

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left"><span>About</span></div> 
            <NavLink to="./about" className="footer-title__about">About Us</NavLink>
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <AlternateEmailIcon color="primary" mailto="mailto:no-reply@example.com" />
            </div>  
              <a href="mailto:xball@xtalball.com">xball@xtalball.com</a>
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <PhoneForwardedIcon color="primary" />
            </div>
            <a href="tel:+972 533 49 3831">+972 533 49 3831</a>  
            </div>  



            <div className="footer-contacts__record">
              <div className="footer-contacts__record--left">
                <a href="https://wa.me/+77059870484" target="_blank" rel="noopener noreferrer">
                  <FaWhatsapp size={32} /> 
                </a>
              </div>  
                <a href="https://wa.me/+972533493831" target="_blank" rel="noopener noreferrer">
                  <span>+972 533 49 3831</span>
                </a>
            </div>  

            <div className="footer-contacts__record">
              <div className="footer-contacts__record--left">
                <a href="https://t.me/xtalball_agent" target="_blank" rel="noopener noreferrer">
                  <FaTelegram size={32} />
                </a>
              </div>  
                <a href="https://t.me/+972544738376" target="_blank" rel="noopener noreferrer">
                  <span>@+972544738376</span>
                </a>
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <BusinessIcon color="primary" />
            </div>  
            <span>Giborey Israel, bld. 24, Netania, Israel</span>
            </div>  


          </div>

          <div className="footer-rights"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
